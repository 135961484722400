import { css } from '@emotion/react';
import { loadingPlaceholderStyles } from 'communications/course_communications/components/loading-placeholder';
import { useSelector } from 'react-redux';
import { isEmpty } from 'underscore';
import { Estimation, LecturePage, NLecturePage, SyncingStatus } from 'redux/schemas/models/lecture-page';
import { useAppDispatch } from 'redux/store';
import { updateLecturePage } from 'redux/actions/lecture-pages';
import { PayloadAction } from '@reduxjs/toolkit';
import { black, hexToRgbaString } from 'styles/global_defaults/colors';
import { largeSpacing } from 'styles/global_defaults/scaffolding';
import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import { AngularServicesContext } from 'react-app';
import React, { useCallback, useContext, useEffect, useState, createContext, useRef } from 'react';
import LecturePageRootSingleton from 'lecture_pages/services/lecture-page-root';
import { useLecturePageFromParams, useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { getCurrentCourse } from 'redux/selectors/course';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import NvCommunityCompletionRow from 'shared/components/nv-community-completion/nv-community-completion-row';
import { CommunityCompletionRowType } from 'redux/schemas/app/lecture-page';
import { isNotDesktop } from 'styles/global_defaults/media-queries';
import { NvSavingOverlay } from 'shared/components/nv-saving-overlay';
import LectureComponentList from './content-area/lecture-component-list';
import LectureContentTopGutter from './content-area/lecture-content-top-gutter';
import LectureContentHeader from './content-area/lecture-content-header-panel';
import { LecturePageUrlParams } from './lecture-page-routes';
import { LecturePageMode } from '.';

export const LecturePageMainPanelContext = createContext(null);

export const useLecturePageMainPanel = () => {
  const lecturepageMainPanelContext = useContext(LecturePageMainPanelContext);

  return lecturepageMainPanelContext.current;
};

export type LecturePageEditContentParams = {
  lecturePage: NLecturePage;
  setIsHoverOnHeader: (isHover: boolean) => void;
  saveLecturePageData: (newData: Partial<NLecturePage> & Partial<Estimation>) => Promise<PayloadAction<any>>;
} & LecturePageUrlParams;

const styles = css`
  flex-grow: 1;
  overflow-y: auto;

  position: relative;

  /** This class is specifically prefixed with 'react-' to indicate that we have a '.background-saving-mask' used in some
  of our angularjs lecture components code (specifically the text-with-image saving mask when uploading an image). Naming these the same
  will cause NOV-68138 */
  .react-background-saving-mask {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${black};
    opacity: .7;
    text-align: center;
    color: white;

    .icon {
      display: inline-block;
      margin-top: ${2 * largeSpacing}px;
      margin-bottom: ${largeSpacing}px;
    }
  }

  .header-container {
    margin-top: -40px;
  }
`;

type LecturePageContentProps = {
  // When true, this left panel will float above the page content
  floatLeftPanel: boolean,
};

/** Root container for all "core" lecture page content, i.e. the area that displays the rendered lecture components,
 * lecture header text, admin cog, etc. */
export const LecturePageContent = (props: LecturePageContentProps) => {
  const params = useLecturePageParams();
  const {
    InteroperabilityRoutes, CurrentPermissionsManager, CurrentUserManager,
  } = useContext(AngularServicesContext);

  const mainPanelScrollableRef = useRef<HTMLDivElement>();

  const lecturePage = useLecturePageFromParams();

  const isLectureLoaded = useSelector(state => lecturePage && state.app.lecturePage.isCurrentLectureLoaded);
  const isFullOutlineLoaded = useSelector(state => state.app.lecturePage.isTimelineContentLoaded);
  const isHeaderLoading = useSelector(state => state.app.lecturePage.isHeaderSaving);
  const isModeSwitching = useSelector(state => state.app.lecturePage.isModeSwitching);
  const isLeftPanelVisible = useSelector(state => state.app.lecturePage.isLeftPanelVisible);
  const isTranslating = useSelector(state => state.app.lecturePage.isTranslating);

  const course = useSelector((state) => getCurrentCourse(state));
  const [isHoverOnHeader, setIsHoverOnHeader] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>();

  const dispatch = useAppDispatch();

  const usePhotoBkg = !!lecturePage?.coverImageUrl;
  const backgroundColor = usePhotoBkg ? 'linear-gradient(rgba(256,256,256,0.1), rgba(256,256,256,0.1))' : (lecturePage?.viewOptions?.backgroundColor ?? 'white');
  const backgroundImage = usePhotoBkg ? `url("${lecturePage.coverImageUrl}")` : 'unset';

  useEffect(() => {
    // Update the root singleton so the Angularjs app is aware of the new page
    // TODO: Currently only used in the light-header template (top bar). Seems like crazy
    // overkill, to create this singleton just for that; I'm open to better ideas on doing this
    LecturePageRootSingleton.currentLecture = lecturePage;
  }, [lecturePage]);

  useEffect(() => {
    if (course.archivedAt) {
      dispatch(addAlertMessage({
        type: AlertMessageType.WARNING,
        duration: Infinity,
        header: t.COURSES.ARCHIVE.WARNING_MESSAGE(),
      }));
    }
  }, []);

  const saveLecturePageData = useCallback((newData: Partial<NLecturePage>) => dispatch(updateLecturePage({
    ...params,
    lecturePage: {
      ...newData,
    },
  })), [dispatch, params]);

  const lectureContentEditParams = { lecturePage, saveLecturePageData, ...params };

  if (
    !isLectureLoaded
    || (params.mode !== LecturePageMode.VIEW && !params.currentCourseIsCollection && !isFullOutlineLoaded)
    || isModeSwitching
    || isTranslating
  ) {
    return <LectureContentLoadingPlaceholder />;
  }

  const canViewLockedContent = CurrentPermissionsManager.isInstructor()
    || CurrentPermissionsManager.isTeachingAssistant()
    || CurrentPermissionsManager.isCourseBuilder()
    || CurrentPermissionsManager.isConfigAndRegistrationRole();

  const isSyncingLink = !params.currentCourseIsCollection
    && (lecturePage.isLinked
    && [
      SyncingStatus.SYNC_IN_PROGRESS,
      SyncingStatus.LINK_IN_PROGRESS,
      SyncingStatus.CONVERT_IN_PROGRESS,
    ].includes(lecturePage.syncingStatus));

  return (
    (!isEmpty(lecturePage) && !isSyncingLink) ? (
      <React.Fragment>
        {!isEmpty(lecturePage?.prerequisite) && !canViewLockedContent ? (
          <div className='locked-content l2-main mx-auto'>
            <NvIcon className='text-gray-3' icon='locked' size='large' />
            <div className='explanation-row'>
              {t.LECTURE_PAGES.LOCKED_EXPLANATION({
                lectureAlias: course.lectureName.downcasedSingularized,
                itemTitle: lecturePage.prerequisite.title,
              })}
            </div>
            <div className='button-row'>
              <a
                href={InteroperabilityRoutes.activityPath(
                  course,
                  lecturePage.prerequisite,
                  CurrentPermissionsManager.hasCourseAdminPermissions(),
                )}
                className='bs4-btn bs4-btn-primary'
              >
                {t.ACTIVITY.TAKE_ME_THERE()}
              </a>
            </div>
          </div>
        ) : (
          <div
            css={styles}
            ref={mainPanelScrollableRef}
            className='main-panel-scrollable'
            // The id attribute is used for supporting the left navigation's `Skip to Content` button accessibility.
            id='lecture-page-main-content'
          >
            <LecturePageMainPanelContext.Provider value={mainPanelScrollableRef}>
              <LectureContentTopGutter
                {...lectureContentEditParams}
                isHoverOnHeader={isHoverOnHeader}
                setIsHoverOnHeader={(newValue) => setIsHoverOnHeader(newValue)}
                contentTrigger={contentRef.current?.offsetTop}
              />
              <div
                className='d-flex flex-column position-relative header-container'
                style={lecturePage.showTitle ? {
                  backgroundColor,
                  backgroundImage,
                  backgroundPosition: 'center, center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover, cover',
                  // Apply the configured foreground color to all text in this top gutter area
                  // Note that due to CSS selector specificity we also have to manually set this on the title in LectureContentHeaderPanel
                  color: lecturePage.viewOptions?.titleColor ?? 'black',
                  minHeight: getLectureHeaderHeight(lecturePage),
                } : {}}
                onMouseEnter={() => setIsHoverOnHeader(true)}
                onMouseLeave={() => setIsHoverOnHeader(false)}
              >
                {lecturePage.showTitle
                  && <LectureContentHeader {...lectureContentEditParams} isHoverOnHeader={isHoverOnHeader} />}
                { isHeaderLoading && (
                  <div className='react-background-saving-mask'>
                    <NvIcon icon='refresh' size='large' className='spin' />
                    <div className='page-subtitle'>{t.LECTURE_PAGES.ADMIN.HEADER.EDIT_HEADER.SAVING()}</div>
                  </div>
                )}
              </div>
              <LectureComponentList ref={contentRef} />
              {!params.currentCourseIsCollection
                && course.isSelfPaced
                && (isEmpty(lecturePage.prerequisite) || CurrentUserManager.isAdmin())
                && lecturePage?.usersRecentlyViewed?.length > 1 && (
                <NvCommunityCompletionRow
                  users={lecturePage.usersRecentlyViewed}
                  activityType={CommunityCompletionRowType.LECTURE}
                  numUsersWhoCompleted={lecturePage.numUsersRecentlyViewed}
                />
              )}
            </LecturePageMainPanelContext.Provider>
          </div>
        )}
        {props.floatLeftPanel && isLeftPanelVisible && (
          <div
            className='position-absolute w-100 h-100'
            style={{ backgroundColor: hexToRgbaString(black, 0.3) }}
          />
        )}

      </React.Fragment>
    ) : (
      <NvSavingOverlay
        isShown
        savingText={t.LECTURE_PAGES.LINKING()}
        isFullPage={false}
      />
    )
  );
};

export const LectureContentLoadingPlaceholder = () => {
  const style = css`
    width: 800px;
    ${isNotDesktop && css`
      width: 80%;
    `}
    margin-left: auto;
    margin-right: auto;
    ${loadingPlaceholderStyles}
  `;

  return (
    <div className='w-100'>
      <div css={style}>
        <div className='loading placeholder-bar' />
        <div className='loading placeholder-bar' />
      </div>
    </div>
  );
};

export const getLectureHeaderHeight = (lecturePage: NLecturePage) => (lecturePage.coverImageUrl ? '300px' : '215px');
export const getLectureHeaderTopMargin = (lecturePage: NLecturePage) => (lecturePage.coverImageUrl ? '100px' : '40px');

export default LecturePageContent;
