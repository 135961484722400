import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { css, SerializedStyles } from '@emotion/react';
import { createPortal } from 'react-dom';
import React, { forwardRef, useState, useEffect, FunctionComponent, SyntheticEvent, useRef, MouseEventHandler } from 'react';
import { quarterSpacing, halfSpacing, standardSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { warning, gray3, gray4, gray5, gray6, primary } from 'styles/global_defaults/colors';
import { textSmallFontSize } from 'styles/global_defaults/fonts';
import { FormCheck } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/Overlay';
import maxSize from 'popper-max-size-modifier';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import { NvTooltip, TextAlign } from 'shared/components/nv-tooltip';
import _ from 'underscore';
import { ConditionalWrap } from 'components/conditional-wrap';
import ClickableContainer from 'components/clickable-container';
import { mergeRefs } from 'shared/react-utils';
import NvIcon from 'shared/components/nv-icon';
import useNativeListeners from 'shared/hooks/use-native-listeners';
import { doubleModalZIndex } from 'shared/components/nv-modal';
import { getApplyMaxSize } from 'shared/utils';

type TooltipForwardedProps = {
  text: string,
  preventOverflow?: boolean
  enabled?: boolean,
  placement?: Placement,
  textAlign?: TextAlign
  offset?: number,
  maxWidth?: number,
};

export enum NvDropdownAlign {
  LEFT = 'Left',
  CENTER = 'Center',
  RIGHT = 'Right',
}

export enum NvDropdownButtonStyle {
  BUTTON,
  CONDENSED_TEXT,
  ICON,
  CUSTOM,
  FORM,
  FORM_SMALL, // same as FORM but with smaller text - intended for legacy features
}

export type NvDropdownProps = {
  show?: boolean,
  title?: string,
  iconClass?: string,
  items: NvDropdownOption[],
  buttonStyle?: NvDropdownButtonStyle,
  buttonClass?: string,
  toggleDataQa?: string,
  toggleDataQaId?: string,
  menuClassName?: string,
  /* Whether to show a checkmark (or some other style) on the currently selected
  * item */
  showSelectedIndicator?: boolean,
  align?: NvDropdownAlign,
  initialIndex?: number,
  minWidth?: number,
  maxWidth?: number,
  /** A creator for the element to draw the dropdown beneath when clicked. Only used
   * When button style is set to 'custom' */
  customTarget?: FunctionComponent<any>,
  onToggle?: (isOpen: boolean, event?: SyntheticEvent<Dropdown>,
    metadata?: any) => void,
  /* The direction of the dropdown */
  drop?: 'up' | 'left' | 'right' | 'down',
  disabled?: boolean,
  /** Whether to allow flipping the dropdown up to avoid overflow */
  flip?: boolean
  offset?: number,
  titleClass?: string,
  /** Configures popper.js to allow overflowing out of the container
   * TODO: This is a last-minute fix added quickly for degreed-integration-r2. We should rework this property
   * to align with how we want this dropdown to be configurable in the future */
  allowOverflow?: boolean,
  altLabel?: string,
  pill?: boolean;
  /** To remove top and bottom padding for the dropdow menu */
  noMenuPadding?: boolean;
  maxSizeInterceptor?: (maxSize: number) => number,
  container?: HTMLElement,
  useMaxHeightModifier?: boolean,
  /** If set, will render the Toggle of this Dropdown with an NvTooltip wrapped around it with the given text
   * Using this instead of wrapping the <NvDropdown> with an NvTooltip allows for making the tooltip disappear
   * even when mousing over items in the dropdown, and prevents an error where the tooltip remains open even after
   * opening a modal via a dropdown option (https://novoed.atlassian.net/browse/NOV-68054) */
  tooltip?: string,
  tooltipTextAlign?: TextAlign
  tooltipPlacement?: Placement
  isFocusable?: boolean,
  /** To remove focus from the dropdown parent when clicking on any dropdown item */
  focusOutOnItemClick?: boolean,
  useEffectFlushFix?: boolean,
  /** In case the dropdown menu is inside a double modal, to make tooltips visible */
  insideDoubleModal?: boolean,
};

const defaultProps: Partial<NvDropdownProps> = {
  buttonStyle: NvDropdownButtonStyle.BUTTON,
  align: NvDropdownAlign.LEFT,
  drop: 'down',
  flip: true,
  allowOverflow: true,
  pill: false,
  toggleDataQa: '',
  useEffectFlushFix: false,
};

type ItemProps = {
  withForm?: boolean;
};

export interface NvDropdownLinkItem {
  type: 'link';
  text: string;
  /** If present, creates this text as an <a> tag so the url can be copied on right-click */
  link: string;
  /** this is required if the items passed to the dropdown is not static */
  id?: number | string;
  /** A function called when an item is selected.
   * @parameter item - The item that was clicked */
  callback?: (...args: any[]) => void;
  disabled?: boolean;
  /**
   * Whether we should navigate the link in a new browser tab
   */
  targetBlank?: boolean;
  dataQa?: string;
  tooltip?: TooltipForwardedProps;
}

export interface NvDropdownTextItem {
  type: 'text';
  text: string;
  textOnSelected?: string;
  value?: any;
  /** this is required if the items passed to the dropdown is not static */
  id?: number | string;
  class?: string;
  /* show tooltip */
  tooltip?: TooltipForwardedProps;
  /** A function called when an item is selected.
   * @parameter item - The item that was clicked */
  callback?: (...args: any[]) => void;
  /**
   * By default react-bootstrap will close the dropdown when an item is clicked.
   * This prevents that auto closing
   */
  preventClosing?: boolean,
  disabled?: boolean;
  /** If true, disables this item setting the active selected item state.
   * This causes this specific item to not show a checkmark when the dropdown's
   * 'showSelectedIndicator' prop is true */
  disableSetActive?: boolean,
  /** Displays an NvIcon to the left of the text */
  iconClass?: string,
  resetItem?: boolean;
  pendoTag?: string;
  textClassName?: string;
  dataQa?: string;
  dataQaPendo?: string;
  description?: string;
  descriptionClass?: string;
  dataJourneyName?: string;
  key?: string;
}

export interface NvDropdownDivider {
  type: 'divider';
  /** this is required if the items passed to the dropdown is not static */
  id?: number | string;
  class?: string;
}

export interface NvDropdownHeader {
  type: 'header';
  title: string;
  /** this is required if the items passed to the dropdown is not static */
  id?: number | string;
  class?: string;
}
export interface NvDropdownAccordionHeader {
  type: 'accordion-header';
  title: string;
  items: NvDropdownOption[];
  /** this is required if the items passed to the dropdown is not static */
  id?: number | string;
  class?: string;
  children?: React.ReactNode;
}

export interface NvDropdownRadio {
  type: 'radio';
  label: string;
  group: string,
  /** this is required if the items passed to the dropdown is not static */
  id?: number | string;
  isChecked?: boolean,
  disabled?: boolean;
  class?: string,
  labelClass?: string,
  callback?: (...args: any[]) => void;
  onChanged?: (e: any) => void;
  itemStyle?: SerializedStyles;
  infoIconTooltipText?: string;
  tooltip?: TooltipForwardedProps;
}

export interface NvDropdownCheckbox {
  type: 'checkbox';
  name: string;
  label: string;
  checked?: boolean;
  /** this is required if the items passed to the dropdown is not static */
  id?: number | string;
  disabled?: boolean;
  class?: string,
  labelClass?: string,
  callback?: (...args: any[]) => void;
  onChanged?: (e: any) => void;
}
export interface NvDropdownCustomItem {
  type: 'custom';
  customItem: JSX.Element;
  /** this is required if the items passed to the dropdown is not static */
  id?: number | string;
  callback?: (...args: any[]) => void;
  disabled?: boolean;
  preventClosing?: boolean;
}

export type NvDropdownOption =
  NvDropdownLinkItem |
  NvDropdownTextItem |
  NvDropdownDivider |
  NvDropdownHeader |
  NvDropdownAccordionHeader |
  NvDropdownRadio |
  NvDropdownCheckbox |
  NvDropdownCustomItem;

function isNvDropdownHeader(item: NvDropdownOption): item is NvDropdownHeader {
  return (item as NvDropdownHeader).type === 'header';
}

function isNvDropdownAccordionHeader(item: NvDropdownOption): item is NvDropdownAccordionHeader {
  return (item as NvDropdownAccordionHeader).type === 'accordion-header';
}

function isNvDropdownDivider(item: NvDropdownOption): item is NvDropdownDivider {
  return (item as NvDropdownDivider).type === 'divider';
}

function isNvDropdownRadio(item: NvDropdownOption): item is NvDropdownRadio {
  return (item as NvDropdownRadio).type === 'radio';
}

function isNvDropdownCheckbox(item: NvDropdownOption): item is NvDropdownCheckbox {
  return (item as NvDropdownCheckbox).type === 'checkbox';
}

function isNvDropdownLinkItem(item: NvDropdownOption): item is NvDropdownLinkItem {
  return (item as NvDropdownLinkItem).type === 'link';
}

function isNvDropdownTextItem(item: NvDropdownOption): item is NvDropdownTextItem {
  return (item as NvDropdownTextItem).type === 'text';
}

function isNvDropdownCustomItem(item: NvDropdownOption): item is NvDropdownCustomItem {
  return (item as NvDropdownCustomItem).type === 'custom';
}

interface ToggleProps {
  children: React.ReactNode
  onClick?: any
  buttonStyle: NvDropdownButtonStyle
  buttonClass?: string
  toggleDataQa?: string
  toggleDataQaId?: string
  styles: any
  iconClass: string
  customTarget: FunctionComponent<any>
  titleClass?: string,
  altLabel?: string,
  disabled?: boolean,
  isFocusable?: boolean,
  useEffectFlushFix: boolean,
}

const Toggle = forwardRef((props: ToggleProps, ref) => {
  const onClickRef = React.useRef<MouseEventHandler>();
  const elementRef = React.useRef<HTMLDivElement>();

  onClickRef.current = props.onClick;

  // Using useNativeListeners instead of onClick prop to fix NOV-79876.
  // Reference: https://github.com/facebook/react/issues/20074
  if (props.useEffectFlushFix) {
    // NOTE: Disabling eslint rule since useEffectFlushFix isn't expected to
    // change
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useNativeListeners(elementRef, {
      click: (e) => {
        // Stopping propagation to fix NOV-79876.
        // Reference: https://github.com/react-bootstrap/react-bootstrap/issues/5409
        e.stopPropagation();
        onClickRef.current?.(e as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>);
      },
    });
  }

  const mergedRef = mergeRefs(ref, elementRef);

  const clickHandler = props.useEffectFlushFix ? undefined : props.onClick;

  switch (props.buttonStyle) {
    case NvDropdownButtonStyle.BUTTON:
      return (
        <Button
          key='toggle'
          ref={mergedRef}
          variant='primary'
          className={`bs4-dropdown-button ${props.buttonClass}`}
          aria-label={props.altLabel}
          data-qa={props.toggleDataQa}
          data-qa-id={props.toggleDataQaId}
          onClick={clickHandler}
          disabled={props.disabled}
        >
          {props.children}
        </Button>
      );
    case NvDropdownButtonStyle.CONDENSED_TEXT:
      return (
        <ClickableContainer
          ref={mergedRef}
          isFocusable={props.isFocusable}
          data-qa={props.toggleDataQa}
          data-qa-id={props.toggleDataQaId}
          onClick={clickHandler}
        >
          <a
            key='toggle'
            css={props.styles}
            className='text-medium condensed font-weight-bold condensed-dropdown'
            aria-label={props.altLabel}
          >
            {props.children}
            <div className='icon text-xs icon-dropdown-arrow' />
          </a>
        </ClickableContainer>
      );
    case NvDropdownButtonStyle.ICON:
      return (
        <ClickableContainer
          key='toggle'
          css={props.styles}
          ref={mergedRef}
          aria-label={props.altLabel}
          className={`icon ${props.iconClass}`}
          isFocusable={props.isFocusable}
          data-qa={props.toggleDataQa}
          data-qa-id={props.toggleDataQaId}
          onClick={clickHandler}
        />
      );
    case NvDropdownButtonStyle.CUSTOM:
      if (!props.customTarget) {
        return null;
      }

      return (
        <ClickableContainer
          layoutOnly={props.disabled}
          key='toggle'
          ref={mergedRef}
          aria-label={props.altLabel}
          isFocusable={props.isFocusable}
          data-qa={props.toggleDataQa}
          data-qa-id={props.toggleDataQaId}
          onClick={clickHandler}
        >
          {props.children}
        </ClickableContainer>
      );

    case NvDropdownButtonStyle.FORM_SMALL:
    case NvDropdownButtonStyle.FORM:
      return (
        <ClickableContainer
          key='toggle'
          ref={mergedRef}
          aria-label={props.altLabel}
          className={`title ${props.buttonClass || ''}`}
          isFocusable={props.isFocusable}
          data-qa={props.toggleDataQa}
          data-qa-id={props.toggleDataQaId}
          onClick={clickHandler}
        >
          <div className={`${props.buttonStyle === NvDropdownButtonStyle.FORM ? 'text-large' : ''} ellipsis ${props.titleClass || ''}`}>{props.children}</div>
          <div className='icon text-xs icon-dropdown-arrow' />
        </ClickableContainer>
      );
    default:
      return null;
  }
});

/** TODO: The types on this have gotten out of control. `any` was used on itemProps before my additions, and several spread operators meant we were
 * applying many properties incorrectly to <divs>. We need to revise this, this should just be
 * the rendering component for an NvDropdownTextItem */
const Item = (itemProps) => {
  const iProps = _.omit(itemProps, 'extraClass', 'toolTip', 'showSelectedIndicator', 'buttonStyle', 'iconClass', 'resetItem', 'textClassName');
  const {
    text,
    disabled,
    resetItem = false,
    toolTip,
    showSelectedIndicator,
    drop,
    iconClass,
    textClassName = '',
    description,
    descriptionclass = '',
    zIndexTooltip,
  } = itemProps;
  let { className } = itemProps;
  if (itemProps.extraClass) {
    className += ` ${itemProps.extraClass}`;
  }

  if (itemProps.buttonStyle) {
    className = (itemProps.buttonStyle === NvDropdownButtonStyle.FORM) ? `text-large-regular ${className}` : className;
  }

  const itemStyles = css`
    display: ${drop === 'right' || drop === 'left' ? 'block' : 'inline-flex'};
    cursor: pointer;
    /* Hide the active check by :default, and show if .active is applied */
    .icon-check {
      color: ${warning};
      display: none;
      padding-left: ${standardSpacing}px;
      margin-left: auto;
    }
    &.active .icon-check {
      display: inline;
    }
    &[disabled] {
      color: ${gray5} !important;
      cursor: default;
    }
  `;

  const itemBody = (
    <React.Fragment>
      <div className={`d-flex align-items-center ${textClassName}`}>
        {iconClass && (
          <div className='pr-2'>
            <NvIcon icon={iconClass} size='sm' />
          </div>
        )}
        <div className={textClassName}>
          {text}
        </div>
        {description && <div className={descriptionclass}>{description}</div>}
      </div>
      {showSelectedIndicator && !resetItem && (
        <i className='icon icon-smallest icon-check float-right' />
      )}
    </React.Fragment>
  );

  return (
    <div key={text} css={itemStyles} role='button' {...iProps} className={className} disabled={disabled || resetItem}>
      {toolTip ? (
        <NvTooltip
          zIndex={zIndexTooltip}
          textAlign={toolTip.textAlign}
          text={toolTip.text}
          placement={toolTip?.placement}
          enabled={toolTip?.enabled}
          preventOverflow={toolTip?.preventOverflow}
          offset={toolTip?.offset}
          maxWidth={toolTip?.maxWidth}
        >
          <div className='item-body'>
            {itemBody}
          </div>
        </NvTooltip>
      ) : (
        <React.Fragment>
          {itemBody}
        </React.Fragment>
      )}
    </div>
  );
};

const Divider = (itemProps: NvDropdownDivider) => {
  const itemStyles = css`
    &.nv-dropdown-divider {
      border-top: 0.2px solid ${gray5};
      margin-top: ${quarterSpacing}px;
      margin-bottom: ${quarterSpacing}px;
      height: 1px;
    }
  `;

  return <div css={itemStyles} className={`nv-dropdown-divider ${itemProps.class}`} />;
};

const Header = (itemProps: NvDropdownHeader) => {
  const itemStyles = css`
    &.nv-dropdown-header {
      font-size: ${textSmallFontSize}px;
      color: ${gray3};
      margin: ${quarterSpacing}px ${standardSpacing}px;

      &:hover {
        cursor: auto;
      }
    }
  `;

  return (
    <div css={itemStyles} className={`nv-dropdown-header ${itemProps.class || ''}`}>
      {itemProps.title}
    </div>
  );
};

const AccordionHeader = (itemProps: NvDropdownAccordionHeader) => {
  const collapseRef = useRef(null);
  const [expanded, setExpanded] = useState(false);

  const itemStyles = css`
    &:not(:first-of-type) {
      margin-top: 1px;
    }
    .accordion-header {
      &.expanded {
        i {
          color: ${warning};
          transform: rotate(180deg);
        }
      }
    }
    .accordion-collapse {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.35s ease;
    }
  `;

  const resetCollapse = () => {
    const collapseElement = collapseRef.current;

    if (collapseElement) {
      if (collapseElement.classList.contains('expanded')) {
        collapseElement.style.maxHeight = `${collapseElement.scrollHeight}px`;
      } else {
        collapseElement.style.maxHeight = null;
      }
    }
  };

  const onToggle = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    resetCollapse();
  }, [expanded]);

  useEffect(() => {
    const collapseElement = collapseRef.current;

    // If items are changed, then reset the max height, OR else some of the items
    // won't be displayed if new items are added dynamically
    if (collapseElement) {
      if (collapseElement.classList.contains('expanded')) {
        collapseElement.style.maxHeight = 'initial';
      }
    }
  }, [itemProps]);

  return (
    <div css={itemStyles}>
      <ClickableContainer
        className={`accordion-header m-0 p-2 text-small bg-info text-gray-2 ${expanded ? 'expanded' : ''}`}
        onClick={() => onToggle()}
      >
        <div className='w-100'>{itemProps.title}</div>
        <div className='pull-right'>
          <NvIcon className='text-xs' size='xss-smallest' icon='dropdown-arrow' />
        </div>
      </ClickableContainer>
      <div
        className={`accordion-collapse ${expanded ? 'expanded' : ''}`}
        ref={collapseRef}
      >
        <div className='p-1 accordion-content'>{itemProps.children}</div>
      </div>
    </div>
  );
};

const RadioItem = (itemProps: NvDropdownRadio) => {
  const {
    disabled,
    tooltip,
  } = itemProps;
  const itemStyles = css`
    height: ${standardSpacing}px;
    margin-left: ${standardSpacing}px;
    margin-right: ${standardSpacing}px;
    margin-top: ${halfSpacing}px;
    margin-bottom: ${halfSpacing}px;
    white-space: nowrap;
    /* TODO: Should we remove these from the base styles as well? */
    .bs4-form-check {
      padding-left: 0;
    }

    ${itemProps.itemStyle};
  `;

  const itemBody = (
    <div css={itemStyles} className={`${itemProps.class || ''}`}>
      <FormCheck
        name={itemProps.group}
      >
        <FormCheck.Input
          type='radio'
          id={itemProps.id?.toString() ?? `${itemProps.group}-${itemProps.id}`}
          onChange={itemProps.onChanged ?? (() => { })}
          checked={itemProps.isChecked}
          disabled={disabled}
        />
        <FormCheck.Label
          htmlFor={itemProps.id?.toString() ?? `${itemProps.group}-${itemProps.id}`}
          className={`cursor-pointer ${itemProps.labelClass}`}
          css={css`
              display: flex!important;
              align-items: center;
            `}
          onClick={() => ((!disabled && itemProps.callback) && itemProps.callback())}
        >
          {itemProps.label}
          {itemProps.infoIconTooltipText && (
            <NvTooltip
              text={itemProps.infoIconTooltipText}
              enabled={!disabled}
            >
              <div css={css`
                margin-left: ${quarterSpacing}px !important;
                color: ${primary};
              `}
              >
                <NvIcon
                  icon='info'
                  size='xss-smallest'
                />
              </div>
            </NvTooltip>
          )}
        </FormCheck.Label>
      </FormCheck>
    </div>
  );

  return (
    tooltip ? (
      <NvTooltip
        textAlign={tooltip.textAlign}
        text={tooltip.text}
        placement={tooltip?.placement}
        enabled={tooltip?.enabled}
        preventOverflow={tooltip?.preventOverflow}
        offset={tooltip?.offset}
      >
        <div className='item-body'>
          {itemBody}
        </div>
      </NvTooltip>
    ) : (
      <React.Fragment>
        {itemBody}
      </React.Fragment>
    )
  );
};

const CheckboxItem = (itemProps: NvDropdownCheckbox) => {
  const itemStyles = css`
    &.nv-checkbox-item {
      padding: ${quarterSpacing}px ${halfSpacing}px;
      label:hover {
        cursor: pointer;
      }
    }
  `;

  const handleCheckboxChange = (e) => itemProps.onChanged?.(e.target.checked);

  return (
    <div css={itemStyles} className={`nv-checkbox-item ${itemProps.class || ''}`}>
      <NvCheckbox
        name={itemProps.name}
        label={itemProps.label}
        labelClassName={`${itemProps.labelClass || 'text-medium'}`}
        checked={itemProps.checked}
        onChange={handleCheckboxChange}
        dataQa={itemProps.label.split(' ').join('_').toUpperCase()}
      />
    </div>
  );
};

const CustomItem = (itemProps: { keepDropdownOpenRef: React.MutableRefObject<boolean> } & NvDropdownCustomItem) => {
  const {
    customItem,
    preventClosing,
    keepDropdownOpenRef,
  } = itemProps;

  const { props: {
    onClick: originalOnClick,
  } } = customItem;

  const handleCustomItemClick = (...args) => {
    if (preventClosing) {
      keepDropdownOpenRef.current = true;
    }

    originalOnClick?.(...args);
  };

  return React.cloneElement(customItem, {
    onClick: handleCustomItemClick,
  });
};


/** Displays a list of options in a React Bootstrap `<Dropdown/>` and allows for picking an individual item, optionally indicating which item is currently selected. See `app\shared\components\nv-dropdown.tsx` for an existing implementation; this version should be more generalized so it can target either `<i />` tags or `<Button />`s. */
export const NvDropdown = (props: NvDropdownProps & ItemProps) => {
  const {
    buttonStyle,
    buttonClass,
    align,
    title,
    minWidth,
    maxWidth,
    iconClass,
    customTarget,
    initialIndex = null,
    items,
    drop,
    titleClass,
    flip,
    offset,
    altLabel,
    noMenuPadding,
    disabled,
    useMaxHeightModifier = false,
    isFocusable,
    toggleDataQa,
    toggleDataQaId,
    insideDoubleModal,
  } = props;

  const [selectedIndex, setSelectedIndex] = useState(initialIndex);

  const keepDropdownOpen = useRef<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (initialIndex >= 0 && initialIndex < items.length) {
      setSelectedIndex(initialIndex);
    }
  }, [initialIndex]);

  // When items are changed dynamically, the popper fail to position the
  // dropdown. A hacky fix here to trigger a resize so the popper will position.
  // https://github.com/react-bootstrap/react-bootstrap/issues/5127
  useEffect(() => {
    if (keepDropdownOpen?.current) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [items]);

  const styles = css`
    cursor: ${disabled ? 'auto' : 'pointer'};
    .bs4-dropdown {
      text-align: center;
      &-button {
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        ${minWidth && css`
          min-width: ${minWidth}px;
        `};
        ${maxWidth && css`
          max-width: ${maxWidth}px;
        `};
        .icon {
          display: inline-block;
          margin-right: ${halfSpacing}px;
        }
        &::after {
          margin-left: ${halfSpacing}
        }
      }
      .condensed-dropdown {
        display: flex;
        align-items: baseline;
        user-select: none;
        .icon {
          padding-left: ${quarterSpacing}px;
        }
      }
      .bs4-dropdown-menu {
        padding-top: ${noMenuPadding ? 0 : quarterSpacing}px;
        padding-bottom: ${noMenuPadding ? 0 : quarterSpacing}px;
        min-width: ${minWidth ?? 0}px;

        ${maxWidth && css`
          max-width: ${maxWidth}px;
        `};

        ${align === NvDropdownAlign.LEFT && css`
          transform: translate3d(-${minWidth ?? 0}px, 20px, 0);
        `};
      }

      ${buttonStyle === NvDropdownButtonStyle.BUTTON && css`
        .bs4-dropdown-menu {
          min-width: 100% !important;
          ${align === NvDropdownAlign.CENTER && css`
            top: 20px !important;
          `};
          div {
            white-space: normal;
          }
        }
      `};

      ${(buttonStyle === NvDropdownButtonStyle.FORM || buttonStyle === NvDropdownButtonStyle.FORM_SMALL) && css`
        border: 1px solid ${gray4};
        border-radius: 5px;
        background: white;
        .title {
          padding: ${halfSpacing}px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 40px;
        }
        .icon-dropdown-arrow {
          color: ${gray3};
        }

        ${disabled && css`
          cursor: default;
          color: ${gray4};
          border: 1px solid ${gray5};

          .icon-dropdown-arrow {
            color: ${gray6};
          }
        `}
      `};
      ${buttonStyle === NvDropdownButtonStyle.CONDENSED_TEXT && css`
        ${disabled && css`
          a {
            cursor: default;
            color: ${gray4};
          }
          .icon-dropdown-arrow {
            color: ${gray6};
          }
        `}
      `};
    }

    ${props.pill && css`
      /* duplicated selector to increase specificity without using !important */
      .bs4-dropdown .icon.icon-dropdown-arrow {
        color: ${primary};
      }
      .bs4-dropdown.bs4-dropdown {
        border-color: ${primary};
        border-radius: 18px;

        .title {
          height: 36px;
          color: ${primary};
          padding: ${halfSpacing}px ${threeQuartersSpacing}px;
          font-size: 12px;
          font-weight: 600;
          gap: ${halfSpacing}px;
        }
      }
      .bs4-dropdown-menu {
        border: none;
        border-radius: 0;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    `};
  `;

  const onItemClicked = (item: NvDropdownTextItem, index: number) => {
    if (!item.disableSetActive) {
      setSelectedIndex(index);
    }
    if (item.preventClosing) {
      keepDropdownOpen.current = true;
    }

    /**
     * Triggering a blur event to focus out from the dropdown parent.
     * Dropdown parent focusing is triggered from the dropdown package file.
     * So used a timeout here to trigger the blur event after focusing.
     */
    setTimeout(() => {
      if (props.focusOutOnItemClick) {
        dropdownRef.current?.blur();
      }
    });

    item.callback?.(item);
  };

  const handleKeyPress = (item: NvDropdownTextItem, index: number, event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      onItemClicked(item, index);
      props.onToggle?.(false);
    }
  };

  const selectedItem = items[selectedIndex ?? 0];

  const toggleChildren = () => {
    if (buttonStyle === NvDropdownButtonStyle.CUSTOM) {
      // customTaget should be called as a function instead of JSX, otherwise,
      // React mounts and re-mounts each render
      return customTarget({ key: 'custom-target', selectedIndex, selectedItem });
    }
    const children = [];
    if (iconClass) {
      children.push(<div key='icon' className={`icon ${iconClass}`} />);
    }
    if (title) {
      children.push(title);
    } else if (selectedItem && isNvDropdownTextItem(selectedItem)) {
      children.push(selectedItem.text);
    }

    return children;
  };

  const getItem = (item: NvDropdownOption, index: number) => {
    if (isNvDropdownDivider(item)) {
      return <Divider key={item.id ?? index} {...item} />;
    }

    if (isNvDropdownHeader(item)) {
      return <Header key={item.id ?? index} {...item} />;
    }

    if (isNvDropdownAccordionHeader(item)) {
      return (
        <AccordionHeader key={item.id ?? index} {...item}>
          {item.items.map((subItem, subIndex) => getItem(subItem, subIndex))}
        </AccordionHeader>
      );
    }

    if (isNvDropdownRadio(item)) {
      return <RadioItem key={item.id ?? index} {...item} />;
    }

    if (isNvDropdownCheckbox(item)) {
      return <CheckboxItem key={item.id ?? index} {...item} />;
    }

    if (isNvDropdownCustomItem(item)) {
      return (
        <CustomItem
          key={item.id ?? index}
          keepDropdownOpenRef={keepDropdownOpen}
          {...item}
        />
      );
    }


    if (isNvDropdownLinkItem(item)
      // This is a trick to make sure we can disable link type items but by
      // treating them as text type items (deafult return is right after this
      // if)
      && !item.disabled
    ) {
      const extraProps = {} as any;

      if (item.targetBlank) {
        extraProps.target = '_blank';
      }
      if (item.dataQa) {
        extraProps['data-qa'] = item.dataQa;
      }

      return (
        <a
          href={item.link}
          key={item.id ?? index}
          onClick={item.callback}
          className='bs4-dropdown-item'
          {...extraProps}

        >
          {item.text}
        </a>
      );
    }

    // Treating this always as a text type item even though this could also be a
    // link type item, this because I want to take advantage of the current
    // implementation of disabling and adding tooltip.
    // Anchor elements are not easy to disable as we might think by just adding
    // disable attribute or pointer-events: none; css property value.
    const textItem = item as NvDropdownTextItem;

    return (
      <Dropdown.Item
        key={textItem.id ?? index}
        disabled={textItem.disabled}
        resetItem={textItem.resetItem}
        active={index === selectedIndex}
        onClick={() => onItemClicked(textItem, index)}
        onKeyPress={(event) => handleKeyPress(textItem, index, event)}
        as={({ onClick, disabled: itemDisabled, resetItem, onKeyPress, ...itemProps }) => (
          // Using RemoveDisabledClass component to make sure any text item
          // is focusable regardless of its disabled state, if the "disabled"
          // class is present the item won't be focusable based on the hardcoded
          // selector from source code:
          // https://github.com/react-bootstrap/react-bootstrap/blob/v1.3.0/src/Dropdown.tsx#L169
          <RemoveDisabledClass {...itemProps}>
            {(withoutDisabledItemProps) => (
              <ClickableContainer
                onClick={onClick}
                disabled={itemDisabled}
                onKeyPress={onKeyPress}
              >
                <Item
                  {...withoutDisabledItemProps}
                  disabled={itemDisabled}
                  resetItem={resetItem}
                  key={textItem.id ?? index}
                  showSelectedIndicator={props.showSelectedIndicator}
                  iconClass={textItem.iconClass}
                  className={itemProps.className}
                  extraClass={textItem.class}
                  text={textItem.textOnSelected || textItem.text}
                  toolTip={textItem.tooltip}
                  buttonStyle={buttonStyle}
                  drop={drop}
                  pendo-tag-name={textItem.pendoTag}
                  tabIndex={0}
                  textClassName={textItem.textClassName}
                  data-qa={textItem.dataQa}
                  data-qa-pendo={textItem.dataQaPendo}
                  description={textItem.description}
                  descriptionclass={textItem.descriptionClass}
                  zIndexTooltip={insideDoubleModal ? doubleModalZIndex : null}
                />
              </ClickableContainer>
            )}
          </RemoveDisabledClass>
        )}
        data-journey-name={textItem.dataJourneyName}
      >
        {item.text}
      </Dropdown.Item>
    );
  };

  const popperConfig: any = {
    modifiers: [
      {
        name: 'hide',
        enabled: false,
      },
    ],
  };

  if (align === NvDropdownAlign.CENTER) {
    popperConfig.modifiers.push({
      name: 'centerDropdown',
      enabled: true,
      phase: 'beforeWrite',
      fn({ state }) {
        return {
          ...state,
          styles: {
            ...state.styles,
            popper: {
              ...state.styles.popper,
              left: '50%',
              transform: `translate3d(-50%, ${state.modifiersData.popperOffsets.y}px, 0)`,
            },
          },
        };
      },
    });
  }

  if (useMaxHeightModifier) {
    const { modifiers: popperModifiers } = popperConfig;

    popperModifiers.splice(
      popperModifiers.length,
      0,
      maxSize,
      getApplyMaxSize(props.maxSizeInterceptor, true),
    );
  }

  if (offset || props.pill) {
    popperConfig.modifiers.push({
      name: 'offset',
      options: {
        // use explicit offset if provided, "2" otherwise because it should be a pill
        offset: () => [0, props.offset ?? 2],
      },
    });
  }

  const dropdownMenu = (
    <Dropdown.Menu
      // bug with v5 of react hook form https://github.com/react-hook-form/react-hook-form/issues/2052
      // render when there is a form so RHF does not do validation incorrectly
      renderOnMount={props.withForm}
      className={props.menuClassName ?? ''}
      flip={flip}
      popperConfig={popperConfig}
    >
      {items.map((item, index) => getItem(item, index))}
    </Dropdown.Menu>
  );

  return (
    <div className='nv-dropdown' css={styles}>
      <Dropdown
        bsPrefix='bs4-dropdown'
        alignRight={align === NvDropdownAlign.RIGHT}
        onToggle={(nextToggle) => {
          if (keepDropdownOpen.current) {
            keepDropdownOpen.current = false;
          } else {
            props.onToggle?.(nextToggle);
          }
        }}
        show={!disabled && props.show}
        drop={drop}
      >
        <ConditionalWrap
          wrap={(children) => (<NvTooltip placement={props.tooltipPlacement} textAlign={props.tooltipTextAlign} text={props.tooltip}><div>{children}</div></NvTooltip>)}
          condition={!!props.tooltip}
        >
          <Dropdown.Toggle
            as={Toggle}
            id='nv-dropdown-toggle' // TODO: this should be unique
            buttonStyle={buttonStyle}
            styles={styles}
            iconClass={iconClass}
            customTarget={customTarget}
            titleClass={titleClass}
            buttonClass={buttonClass}
            altLabel={altLabel}
            disabled={disabled || !items.length}
            isFocusable={isFocusable}
            toggleDataQa={toggleDataQa}
            toggleDataQaId={toggleDataQaId}
            ref={dropdownRef}
            useEffectFlushFix={props.useEffectFlushFix}
          >
            {toggleChildren()}
          </Dropdown.Toggle>
        </ConditionalWrap>
        {props.container ? createPortal(
          dropdownMenu,
          props.container,
        ) : dropdownMenu}
      </Dropdown>
    </div>
  );
};

NvDropdown.defaultProps = defaultProps;

/**
 * Component that removes the "disabled" class from the className if present.
 */
type RemoveDisabledClassProps = {
  className?: string,
  children: (propsWithoutDisabledClass: { className?: string } & Object) => React.ReactElement,
} & Object;

const RemoveDisabledClass = (props: RemoveDisabledClassProps) => {
  const {
    children,
    className,
    ...restProps
  } = props;

  return children({
    ...restProps,
    className: className?.replace('disabled', '').trim(),
  });
};

export default NvDropdown;
