/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import t from 'react-translate';
import _ from 'underscore';
import moment from 'moment';

// Form
import { useFormContext } from 'react-hook-form';

// Reused components
import NvDropdown, { NvDropdownOption, NvDropdownButtonStyle } from 'shared/components/inputs/nv-dropdown';

// Styles
import { css } from '@emotion/react';
import { boldFontWeight, openSans, semiBoldFontWeight, textMediumFontSize, textMediumLineHeight, textSmallFontSize } from 'styles/global_defaults/fonts';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { black, white } from 'styles/global_defaults/colors';

// Types
import { Course } from 'redux/schemas/models/course';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import NvCourseAvatar from 'components/nv-course-avatar';
import { useSelector } from 'react-redux';
import NvTooltip from 'shared/components/nv-tooltip';
import { CourseInformationFormCourse as FormCourse, ReleaseDatesType } from './course-information-clone-modal';
import { CloneOptions } from './journey-collection';

import InputClickToEdit, { InputClickToEditTypes } from './input-click-to-edit';
import { CombinedInstitution } from '../../../redux/schemas';

export type CourseClone = Pick<Course, 'catalogId' | 'name' | 'thumbnail' | 'officialReleaseDate' | 'headerColor' | 'id' | 'archivedAt'> & {
  cloneOption: string,
  releaseDateDraft: string,
};

type CourseCollectionProps = {
  base: FormCourse,
  startDate: string,
  course: CourseClone,
  courseIndex: number,
  releaseDates?: ReleaseDatesType,
  collectionIndex: number,
  onOptionChange: (courseIndex: number, collectionIndex: number, option: CloneOptions) => void,
};

const CourseCollection = (props: CourseCollectionProps) => {
  const {
    base,
    startDate,
    course: courseDraft,
    courseIndex,
    releaseDates,
    collectionIndex,
    onOptionChange,
  } = props;

  const institution = useSelector((state) => getCurrentInstitution(state)) as CombinedInstitution;
  const [course, setCourse] = React.useState(courseDraft);
  const { name, thumbnail, catalogId } = course;
  const courseStartDate = course.releaseDateDraft || course.officialReleaseDate || '';
  const coursePath = `collections.${collectionIndex}.courses.${courseIndex}`;

  const [cloneOption, setCloneOption] = React.useState(course.archivedAt ? CloneOptions.EXCLUDE : (course.cloneOption || CloneOptions.CLONE));
  const [cloneClass, setCloneClass] = React.useState(course.archivedAt ? 'exclude-item' : '');
  const [rowHover, setRowHover] = React.useState(false);
  const courseRef = React.useRef(base);
  const disabled = cloneOption === CloneOptions.EXCLUDE || cloneOption === CloneOptions.KEEP;

  const { setValue } = useFormContext();

  const resetCourse = (selectedOption: CloneOptions) => {
    const temp = _.pick(courseRef.current, 'catalogId', 'name', 'thumbnail', 'officialReleaseDate', 'headerColor', 'id', 'archivedAt');
    const newCourse = _.clone(temp) as CourseClone;
    newCourse.cloneOption = selectedOption;
    setCourse(newCourse);
  };

  const options = [
    {
      value: CloneOptions.CLONE,
      label: t.LEARNING_JOURNEYS.CLONE.CLONE_COURSE(),
    },
    {
      value: CloneOptions.KEEP,
      label: t.LEARNING_JOURNEYS.CLONE.KEEP_COURSE(),
      labelOnSelected: t.LEARNING_JOURNEYS.CLONE.KEEP_COURSE_ON_SELECTED(),
    },
    {
      value: CloneOptions.EXCLUDE,
      label: t.LEARNING_JOURNEYS.CLONE.EXCLUDE_COURSE(),
    },
  ];

  const dropdownItems: NvDropdownOption[] = options.map((option) => ({
    type: 'text',
    text: option.label,
    textOnSelected: option.labelOnSelected || option.label,
    dataQa: `${option.value}-${courseIndex}`,
    dataQaPendo: option.value,
    callback: () => handleCloneChange(option.value),
  }));

  const handleCloneChange = (selectedOption) => {
    switch (selectedOption) {
      case CloneOptions.KEEP: {
        resetCourse(selectedOption);
        setCloneClass('keep-item');
        break;
      }
      case CloneOptions.EXCLUDE: {
        resetCourse(selectedOption);
        setCloneClass('exclude-item');
        break;
      }
      default: {
        setCourse({ ...course, cloneOption: selectedOption });
        setCloneClass('');
      }
    }
    setCloneOption(selectedOption);
    onOptionChange?.(courseIndex, collectionIndex, selectedOption);
  };

  const cloneOptionsObject = {
    [CloneOptions.CLONE]: { index: 0, tooltip: t.LEARNING_JOURNEYS.CLONE.CLONE_COURSE_TOOLTIP() },
    [CloneOptions.KEEP]: { index: 1, tooltip: t.LEARNING_JOURNEYS.CLONE.KEEP_COURSE_TOOLTIP() },
    [CloneOptions.EXCLUDE]: { index: 2, tooltip: t.LEARNING_JOURNEYS.CLONE.EXCLUDE_COURSE_TOOLTIP() },
  };

  const getCloneOptionProp = (prop) => cloneOptionsObject[cloneOption][prop];

  const getCloneOptionIndex = () => getCloneOptionProp('index');

  const getTooltipText = () => getCloneOptionProp('tooltip');

  const courseCollectionStyles = css`
    display: grid;
    grid-template-columns: 43% 23% 22% 8%;
    gap: ${halfSpacing}px;
    padding: ${halfSpacing}px ${halfSpacing}px;
    border-bottom: 1px solid ${white};
    &.exclude-item {
      > div:not(.clone-options, .course-name) {
        opacity: 0.5;
      }
    }
    &.keep-item {
      > div:not(.clone-options, .course-name) {
        opacity: 0.75;
      }
    }
    .course-name {
      .label {
        font-size: ${textMediumFontSize}px;
        line-height: ${textMediumLineHeight}px;
        font-weight: ${boldFontWeight};
        margin-left: ${halfSpacing}px;
      }
    }
    .label {
      padding-left: 0;
      color: ${black};
    }
    .text-medium.condensed {
      font-size: ${textSmallFontSize}px;
      line-height: ${textSmallFontSize}px;
      font-weight: ${semiBoldFontWeight};
      font-family: ${openSans};
    }
  `;

  const getCourseStartDate = () => {
    const { journeyBase, journeyDraft } = releaseDates;
    const journeyBaseDate = moment(journeyBase);
    const courseBaseDate = moment(base.officialReleaseDate);
    const diff = courseBaseDate.diff(journeyBaseDate);
    const courseDraftDate: moment.Moment = moment(journeyDraft).add(diff);
    return courseDraftDate;
  };

  React.useEffect(() => {
    if (!_.isEmpty(releaseDates) && base.officialReleaseDate && !course.releaseDateDraft) {
      const courseStartDateDraft: moment.Moment = getCourseStartDate();
      setValue(`${coursePath}.releaseDate`, courseStartDateDraft);
    }
  }, [releaseDates]);

  React.useEffect(() => {
    setValue(coursePath, course);
    setValue(`${coursePath}.releaseDate`, courseStartDate);
  }, [course]);

  React.useEffect(() => {
    if (!disabled && startDate) {
      setValue(`${coursePath}.releaseDate`, startDate);
    }
  }, [startDate]);

  React.useEffect(() => {
    if (course.cloneOption) {
      setValue(`${coursePath}.cloneOption`, course.cloneOption);
    }
  }, [course.cloneOption]);

  React.useEffect(() => {
    if (course.archivedAt) {
      resetCourse(CloneOptions.EXCLUDE);
    }
  }, [course.archivedAt]);

  return (
    <NvTooltip text={t.LEARNING_JOURNEYS.CLONE.ARCHIVED_COURSE(course.name)} placement='top' enabled={!!course.archivedAt}>
      <div css={courseCollectionStyles} className={cloneClass}>
        <div className='course-name d-flex align-items-center'>
          <NvCourseAvatar
            course={course as any}
            institution={institution}
            size='md'
            imageUrl={thumbnail}
          />
          <InputClickToEdit
            name={`${coursePath}.name`}
            initialValue={name}
            disabled={disabled}
            forceHover={rowHover}
            dataQa={`course-name-${courseIndex}`}
            dataQaPendo='course-name'
            ariaLabel={t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.COURSE_INFORMATION.COURSE_NAME()}
            maxLength={340}
            onHover={() => setRowHover(true)}
            onBlur={() => setRowHover(false)}
          />
        </div>
        <div className='d-flex align-items-center justify-content-center'>
          <InputClickToEdit
            name={`${coursePath}.catalogId`}
            initialValue={catalogId}
            disabled={disabled}
            forceHover={rowHover}
            dataQa={`course-catalog-id-${courseIndex}`}
            dataQaPendo='course-catalog-id'
            ariaLabel={t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.CATALOG_ID()}
            onHover={() => setRowHover(true)}
            onBlur={() => setRowHover(false)}
          />
        </div>
        <div className='d-flex align-items-center justify-content-center release-date'>
          <InputClickToEdit
            name={`${coursePath}.releaseDate`}
            initialValue={courseStartDate}
            disabled={disabled}
            forceHover={rowHover}
            dataQa={`course-start-date-${courseIndex}`}
            dataQaPendo='course-start-date'
            ariaLabel={t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.RELEASE_DATE()}
            onHover={() => setRowHover(true)}
            onBlur={() => setRowHover(false)}
            type={InputClickToEditTypes.DATEPICKER}
            placeholder={t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.START_DATE_PLACEHOLDER()}
            keepingErrorAfterClick
          />
        </div>
        <NvTooltip text={getTooltipText()} placement='top' enabled={!course.archivedAt}>
          <div className='clone-options d-flex align-items-center justify-content-end'>
            <NvDropdown
              items={dropdownItems}
              initialIndex={getCloneOptionIndex()}
              buttonStyle={NvDropdownButtonStyle.CONDENSED_TEXT}
              showSelectedIndicator
              disabled={!!course.archivedAt}
            />
          </div>
        </NvTooltip>
      </div>
    </NvTooltip>
  );
};
export default CourseCollection;
