import store from 'redux/store';
import { setShowCourseCompletionStatusModal } from 'redux/actions/courses';
import CourseCompletionStatusModal from '../components/course-completion-status-modal';
import { addAlertMessage } from '../../redux/actions/alert-messages';
import { AlertMessageType } from '../../redux/schemas/app/alert-message';
import t from '../../react-translate';

/* @ngInject */
export default function CourseHomeController(
  $scope,
  $uibModal,
  CurrentCourseManager,
  CourseHomeManager,
  CourseHomeModel,
  TimelinesManager,
  ReportsManager,
  ReportModel,
  LecturePageManager,
  LearningJourneyService,
) {
  const vm = this;

  _.extend(this, {
    CurrentCourseManager,
    CourseHomeManager,
    TimelinesManager,
    ReportsManager,
  });

  initialize();
  initializeReactApp();

  function initialize() {
    // if coming to this page from either a single report view or gallery view, the reports list is stale or incorrect, so need to refresh.
    getTrendingSubmissions(CurrentCourseManager.course.catalogId);

    ReportsManager.getApprovalExercises();

    LecturePageManager.getLecturePages();
  }

  function getTrendingSubmissions(catalogId) {
    return CourseHomeModel.getTrendingSubmissions(catalogId).then((response) => {
      if (response.data) {
        vm.trendingSubmissions = _.each(response.data.slice(0, 6), ReportModel.normalizeSubmissionData);
        // prevent slick carousel from breaking when ReportsManager tries to update this
        vm.carouselSubmissions = angular.copy(vm.trendingSubmissions);
      }
    });
  }

  if (CourseHomeManager.courseHome.showWelcomeMessageAtTop && CurrentCourseManager.course.isDecayEnabled()) {
    $uibModal.open({
      templateUrl: 'course_home/templates/points-decay-modal.html',
      windowClass: 'points-modal',
      controller: 'PointsModalCtrl as vm',
      resolve: {
        pointsReceived: 10,
        leaderboardPoints: 10,
        extras: null,
      },
    });
  }

  function initializeReactApp() {
    LearningJourneyService.listenToJourneyCompletions();

    $scope.CourseCompletionStatusModal = CourseCompletionStatusModal;

    // Ensuring the course completion status modal is closed
    store.dispatch(setShowCourseCompletionStatusModal(false));
    // Show a pop up message when the course is archived.
    if (CurrentCourseManager.course.archivedAt) {
      store.dispatch(addAlertMessage({
        type: AlertMessageType.WARNING,
        duration: Infinity,
        header: t.COURSES.ARCHIVE.WARNING_MESSAGE(),
      }));
    }
    $scope.$on('$destroy', () => {
      LearningJourneyService.unlistenToJourneyCompletions();
    });
  }

  return this;
}
